/**
 * Calculates and returns totals based on provided Data collection.
 *
 * @param data
 * @param key
 * @param initialValue If initialValue is specified, it is used as the initial value to start the accumulation.
 * @param reverse If reverse is true, then totals will be negative value.
 */
export function sum<T, K extends keyof T>(data: T[], key: K, initialValue = 0, reverse = false): number {
  return data.reduce((acc, cur) => (reverse ? acc - Number(cur[key]) : acc + Number(cur[key])), initialValue);
}

/**
 * Uses the parent sum() function to define the average result.
 *
 * @param data
 * @param key
 * @param round If round is true, then total will be rounded
 * @param initialValue If initialValue is specified, it is used as the initial value to start the accumulation.
 * @param reverse If reverse is true, then totals will be negative value.
 */
export function avg<T, K extends keyof T>(data: T[], key: K, round = false, initialValue = 0, reverse = false): number {
  const averageAmount = data.length > 0 ? sum(data, key, initialValue, reverse) / data.length : 0;
  return round ? Math.round(averageAmount) : averageAmount;
}
