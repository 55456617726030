import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { transactionStatementsTableColumns } from '@bootstrap/components/transaction-statement/transaction-statements-list/TransactionStatementList.constants';
import { useLoadMoreQuery } from '@bootstrap/hooks/useLoadMoreQuery';
import { TableErrorCard } from '@factoring/shared/components/table-error-card';
import {
  TransactionStatementsDocument,
  TransactionStatementsQuery,
  TransactionStatementsQueryVariables,
} from '@factoring/types/generated.hooks';
import { ColorNeutral800, ShadowDefault } from '@hub/design-tokens';
import { Paper } from '@ui/paper';
import { Table } from '@ui/table';
import { TableTop } from '@ui/table/TableAddons';
import { Typography, TypographyType } from '@ui/typography';

export const DashboardStatementsTable = () => {
  const navigate = useNavigate();
  const columns = transactionStatementsTableColumns(false);
  const { data, error, loading, refetch } = useLoadMoreQuery<
    TransactionStatementsQuery,
    TransactionStatementsQueryVariables
  >({
    document: TransactionStatementsDocument,
    variables: {
      payload: {
        pagination: { limit: 5 },
        ordering: {
          field: 'CREATED',
        },
      },
    },
    itemsNodeName: 'items',
  });

  return (
    <Paper boxShadow={ShadowDefault}>
      <TableTop
        title={
          <Typography type={TypographyType.HEADING_S} color={ColorNeutral800}>
            <FormattedMessage id="dashboard.latestTransactionStatements" />
          </Typography>
        }
      />

      <Table
        loadingHeight="200px"
        isError={!!error}
        isLoading={loading}
        data={data}
        columns={columns}
        onRowClick={(row) => navigate(`/transaction-statements/${row.uuid}`)}
        keyField="uuid"
        errorComponent={<TableErrorCard titleKey="error.oops" refetch={refetch} />}
      />
    </Paper>
  );
};
